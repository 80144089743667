import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const api = axios.create({ baseURL: `${process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_BACKEND_DEVELOPMENT : process.env.REACT_APP_BACKEND_PRODUCTION}/api` });

api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status >= 400) {
        if (error.response.data && error.response.data.message) {
          error.message = error.response.data.message;
        }
      }
      return Promise.reject(error);
    }
  );

// Helper functions to get, set, and check token expiration
const getAccessToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refreshToken');

const setAccessToken = (token) => localStorage.setItem('token', token);

const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
};

// Function to refresh the access token
const refreshAuthToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) throw new Error("No refresh token available");

    const response = await api.post('/users/refresh-token', { refreshToken });
    const newAccessToken = response.data.accessToken;
    setAccessToken(newAccessToken);
    return newAccessToken;
};

// Function to make an API request with token handling
const makeAuthenticatedRequest = async (auth, method, url, data = null) => {
  try {
    const config = { method, url, data };
    if (auth) {
        let accessToken = getAccessToken();
        if (!accessToken || isTokenExpired(accessToken)) {
            try {
                accessToken = await refreshAuthToken();
            } catch (error) {
                console.error("Token refresh failed. Redirecting to login.");
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                throw error; // Redirect to login or handle error as needed
            }
        }
        config['headers'] = {
            Authorization: `Bearer ${accessToken}`
        }
    }
    return api(config);
  } catch (error) {
    
  }
};

export { makeAuthenticatedRequest };