import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { format, addMonths } from 'date-fns';

function LineChart({ allTreasuries = [], items = [] }) {
  // Early return if no data
  if (!items || items.length === 0) {
    return <p>No data to analyze. Add some treasuries and try again.</p>;
  }

  // Safely extract and format dates from transactions
  const uniqueDates = new Set();
  items.forEach(item => {
    if (item && item.date) {
      try {
        const date = new Date(item.date);
        if (!isNaN(date.getTime())) {  // Validate date is valid
          uniqueDates.add(format(date, 'yyyy-MM'));
        }
      } catch (error) {
        console.error('Error processing date:', error);
      }
    }
  });

  // Sort dates and get earliest/latest
  const sortedDates = Array.from(uniqueDates).sort();
  const earliestDate = sortedDates[0];
  const lastDate = sortedDates[sortedDates.length - 1];

  // Generate array of all months between earliest and current
  const allMonths = [];
  if (earliestDate) {
    
    let current = new Date(earliestDate);
    const end = new Date(lastDate);
    
    while (current <= end) {
      allMonths.push(format(current, 'yyyy-MM'));
      current = addMonths(current, 1);
    }
  }

  // Format month labels
  const monthLabels = allMonths.map(date => {
    try {
      return format(new Date(date), 'MMM yyyy');
    } catch {
      return date; // Fallback if formatting fails
    }
  });

  // Prepare datasets with error handling
  const datasets = allTreasuries.map(treasury => {
    let lastKnownBalance = null;

    const monthlyBalances = monthLabels.map(monthLabel => {
      try {
        const monthKey = format(new Date(monthLabel), 'yyyy-MM');
        
        // Get all balances for this month
        const monthBalances = treasury.Balances
          .filter(balance => balance && balance.date)
          .filter(balance => format(new Date(balance.date), 'yyyy-MM') === monthKey)
          .map(balance => ({
            ...balance,
            balance: treasury.type === 'credit'
              ? Number(balance.balance) - Number(balance.limit || 0)
              : Number(balance.balance)
          }));

        if (monthBalances.length > 0) {
          lastKnownBalance = monthBalances[0].balance;
          return lastKnownBalance;
        }

        return lastKnownBalance;
      } catch (error) {
        console.error('Error processing monthly balance:', error);
        return lastKnownBalance;
      }
    });

    return {
      label: `${treasury.name} (${treasury.type === 'credit' ? 'Credit Card' : 'Account'})`,
      data: monthlyBalances,
      borderColor: treasury.type === 'credit' ? 'rgba(255, 99, 132, 1)' : 'rgba(75, 192, 192, 1)',
      backgroundColor: treasury.type === 'credit' ? 'rgba(255, 99, 132, 0.2)' : 'rgba(75, 192, 192, 0.2)',
      fill: true,
      tension: 0.4,
      pointStyle: treasury.type === 'credit' ? 'rectRot' : 'circle',
      pointRadius: 4
    };
  });

  // Calculate and add additional datasets (Expected/Actual Balance)
  const monthlyData = {};
  const expectedBalances = {};
  let runningExpectedBalance = 0;

  monthLabels.forEach((month, index) => {
    try {
      const Balances = [];
      allTreasuries.forEach(treasury => {
        if (treasury && treasury.Balances) {
          const Balance = treasury.Balances.find(b => 
            b && b.date && format(new Date(b.date), 'MMM yyyy') === month
          );
          
          if (Balance) {
            Balances.push({ ...Balance, type: treasury.type });
          } else if (index > 0 && monthLabels[index - 1]) {
            const previousBalance = treasury.Balances.find(b => 
              b && b.date && format(new Date(b.date), 'MMM yyyy') === monthLabels[index - 1]
            );
            if (previousBalance) {
              Balances.push({ ...previousBalance, type: treasury.type });
            }
          }
        }
      });

      const actualBalance = Balances.reduce((sum, balance) => {
        const currentBalance = Number(balance.balance) || 0;
        const limit = Number(balance.limit || 0);
        return sum + (balance.type === 'debit' ? currentBalance : currentBalance - limit);
      }, 0);

      if (index === 0) {
        runningExpectedBalance = actualBalance;
      } else {
        const netFlow = items
          .filter(item => item && item.date && format(new Date(item.date), 'MMM yyyy') === month)
          .reduce((sum, item) => sum + (Number(item.credit) || 0) - (Number(item.debit) || 0), 0);
        runningExpectedBalance += netFlow;
      }

      expectedBalances[month] = runningExpectedBalance;
      monthlyData[month] = actualBalance;
    } catch (error) {
      console.error('Error processing monthly data:', error);
    }
  });

  // Add Expected and Actual Balance datasets
  datasets.push({
    label: 'Expected Balance',
    data: monthLabels.map(month => expectedBalances[month] || null),
    borderColor: 'rgba(153, 102, 255, 1)',
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
    borderDash: [5, 5],
    fill: false
  });

  datasets.push({
    label: 'Actual Balance',
    data: monthLabels.map(month => monthlyData[month] || null),
    borderColor: 'rgba(75, 192, 192, 1)',
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderDash: [5, 5],
    fill: false
  });

  return (
    <div className="chart">
      <h3>Balance History and Cash Flow</h3>
      <Line
        data={{
          labels: monthLabels,
          datasets
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: { title: { display: true, text: 'Date' } },
            y: { title: { display: true, text: 'Amount' }, stacked: false }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  return `${context.dataset.label}: ${context.raw?.toFixed(2) || 'N/A'}`;
                }
              }
            }
          }
        }}
      />
    </div>
  );
}

export default LineChart;