import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {treasuriesCache, allTreasuriesCache} from '../services/cache';
import DeleteModal from '../Modals/DeleteModal';
import Spinner from '../Modals/Spinner';
import AddEditTreasuryModal from '../Modals/AddEditTreasuryModal';
import AddBalanceModal from '../Modals/AddBalanceModal';
import TreasuryDataTable from '../components/TreasuryDataTable';
import TreasuryDataTableActions from '../components/TreasuryDataTableActions';
import TreasuryBulkUploadModal from '../Modals/TreasuryBulkUploadModal';
import Balance from '../Modals/Balance';
import '../styles/Treasury.css';

function Treasury({ user, currencies, showModal }) {
  const [loading, setLoading] = useState(true);
  const [treasuries, setTreasuries] = useState([]);
  const [editingTreasuryId, setEditingTreasuryId] = useState('');
  const [isAddEditTreasuryModalOpen, setIsAddEditTreasuryModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [deleteAction, setDeleteAction] = useState(null);
  const [direction, setDirection] = useState(true);
  const [currentSortIndex, setCurrentSortIndex] = useState(0);
  const [isUpdateBalanceModalOpen, setIsUpdateBalanceModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [currency, setCurrency] = useState(localStorage.getItem("curr") || user.defaultCurrency || '');

  useEffect(() => {
    fetchTreasuries(direction, currentSortIndex, currency);
  }, []);

  const fetchTreasuries = async(direction, currentSortIndex, currency) => {
    const cacheKey = treasuriesCache.getKey(user.id, direction, currentSortIndex, currency);
    const cachedData = treasuriesCache.get(cacheKey);
    if (cachedData) {
      setTreasuries(cachedData.treasuries);
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const response = await makeAuthenticatedRequest(true, 'get', `/treasuries/user/${user.id}?currency=${currency}`);
      if (Array.isArray(response.data)) {
        const sortedT = sortTreasuries(response.data, direction, currentSortIndex, currency);
        treasuriesCache.set(cacheKey, { treasuries: sortedT });
        setTreasuries(sortedT);
      }
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };
  function sortTreasuries (treasuries, direction, currentSortIndex){
    const sorted = [...treasuries].sort((a, b) => {
        let comparison = 0;
        switch (currentSortIndex) {
          case 0:
            comparison = a.name.localeCompare(b.name || '');
            break;
          case 1:
            comparison = a.currency.localeCompare(b.currency || '');
            break;
          case 2:
            comparison = a.type.localeCompare(b.type || '');
            break;
          case 3:
            comparison = Number(a.limit) - Number(b.limit);
            break;
          case 4:
            comparison = Number(a.lastBalance) - Number(b.lastBalance);
            break;
          default:
            break;
        }
        return direction ? comparison : -comparison;
      });
      return sorted;
  }
  const openUploadModal = () => setIsUploadModalOpen(true);
  const openDeleteModal = (title, action) => {
    setModalTitle(title);
    setDeleteAction(() => action);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteTreasury = () => {openDeleteModal('Treasury', () => deleteTreasury());};
  const deleteTreasury = async () => {
    try {
      await makeAuthenticatedRequest(true, 'delete', `/treasuries/${editingTreasuryId}`);
      treasuriesCache.clear();
      allTreasuriesCache.clear();
      await fetchTreasuries(direction, currentSortIndex, currency);
      setEditingTreasuryId('');
      setIsDeleteModalOpen(false);
    } catch (error) {
      showModal("Failed to delete treasury");
    }
  };

  const openAddEditTreasuryModal = () => setIsAddEditTreasuryModalOpen(true);
  const openUpdateBalanceModal = () => setIsUpdateBalanceModalOpen(true);
  const openHistoryModal = () => setIsHistoryModalOpen(true);

  if (!user || !user.id) return null; // Prevent rendering if user or user.id is missing

  return (
    <div className="treasury-page">
      <div className="treasury-table-container">
        <TreasuryDataTableActions
          handleDeleteTreasury={handleDeleteTreasury}
          openAddEditTreasuryModal={openAddEditTreasuryModal}
          openUpdateBalanceModal={openUpdateBalanceModal}
          openUploadModal={openUploadModal}
          openHistoryModal={openHistoryModal}
          editingTreasuryId={editingTreasuryId}
        />
        <TreasuryDataTable
          treasuries={treasuries} currencies={currencies}
          editingTreasuryId={editingTreasuryId} 
          setEditingTreasuryId={setEditingTreasuryId}
          fetchTreasuries={fetchTreasuries} 
          direction={direction} setDirection={setDirection}
          currentSortIndex={currentSortIndex} setCurrentSortIndex={setCurrentSortIndex}
          currency={currency} setCurrency={setCurrency}
        />
      </div>
      {isHistoryModalOpen && editingTreasuryId && (
        <Balance
          id={editingTreasuryId}
          currencies={currencies}
          currency={currency}
          onClose={() => setIsHistoryModalOpen(false)}
          fetchTreasuries={async() => await fetchTreasuries(direction, currentSortIndex, currency)}
        />
      )}
      {isAddEditTreasuryModalOpen && (
        <AddEditTreasuryModal
          user={user} currencies={currencies}
          onClose={() => setIsAddEditTreasuryModalOpen(false)}
          treasuries={treasuries} Incurrency={currency}
          editingTreasuryId={editingTreasuryId}
          fetchTreasuries={async() => await fetchTreasuries(direction, currentSortIndex, currency)}
        />
      )}
      {isUploadModalOpen && 
      <TreasuryBulkUploadModal
        user={user}
        onClose={() => setIsUploadModalOpen(false)}
        fetchTreasuries={async() => await fetchTreasuries(direction, currentSortIndex, currency)}
      />}
      {isUpdateBalanceModalOpen && (
        <AddBalanceModal
          onClose={() => setIsUpdateBalanceModalOpen(false)}
          fetchTreasuries={async() => await fetchTreasuries(direction, currentSortIndex, currency)}
          treasury={treasuries.find(t => t.id == editingTreasuryId)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          modalTitle={modalTitle}
          deleteAction={deleteAction}
        />
      )}
      {loading &&
        <Spinner message="Loading Data...."/>}
    </div>
  );
}
export default Treasury; 