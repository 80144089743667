import '../styles/CurrencyModal.css';

const CurrencySelector = ({ allCurrencies, selectedCurrencies, setSelectedCurrencies, defaultCurrency, setDefaultCurrency }) => {
    return (
      <div className="currency-selector">
        <div className="checkbox-list">
          {allCurrencies.sort((a, b) => a.name.localeCompare(b.name)).map((currency) => (
            <div key={currency.abbreviation} className="checkbox-item">
              <input
                type="checkbox"
                id={`currency-${currency.abbreviation}`}
                checked={selectedCurrencies.includes(currency.abbreviation)}
                onChange={() => {
                  const newSelection = selectedCurrencies.includes(currency.abbreviation)
                    ? selectedCurrencies.filter(c => c !== currency.abbreviation)
                    : [...selectedCurrencies, currency.abbreviation];
                  setSelectedCurrencies(newSelection);
                  
                  // If default currency is deselected, update it
                  if (defaultCurrency === currency.abbreviation && !newSelection.includes(currency.abbreviation)) {
                    setDefaultCurrency(newSelection[0] || '');
                  }
                }}
              />
              <label htmlFor={`currency-${currency.abbreviation}`}>
                {currency.name} ({currency.abbreviation})
              </label>
            </div>
          ))}
        </div>
  
        {selectedCurrencies.length > 0 && (
          <div className="default-currency-select">
            <label>Default Currency:</label>
            <select
              value={defaultCurrency}
              onChange={(e) => setDefaultCurrency(e.target.value)}
              className={!defaultCurrency ? 'input-error' : ''}
            >
              <option value="">Select Default Currency</option>
              {selectedCurrencies.map(curr => {
                const currency = allCurrencies.find(c => c.abbreviation === curr);
                return (
                  <option key={curr} value={curr}>
                    {currency.name} ({curr})
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
    );
  };

export default CurrencySelector;