export class StorageCache {
  constructor(prefix = 'cache_', maxSize = 100, maxStorageSize = 4.5 * 1024 * 1024) { // 4.5MB default limit
    this.prefix = prefix;
    this.maxSize = maxSize;
    this.maxStorageSize = maxStorageSize;
    this.storageKey = `${prefix}storage`;
    this.initializeStorage();
  }

  initializeStorage() {
    if (!localStorage.getItem(this.storageKey)) {
      localStorage.setItem(this.storageKey, JSON.stringify({
        items: {},
        lru: [],
        timestamp: Date.now()
      }));
    }
  }

  getKey(...args) {
    const params = {};
    args.forEach((arg, index) => {
      if (arg !== undefined && arg !== null) {
        params[`param${index}`] = arg;
      }
    });
    return btoa(JSON.stringify(params));
  }

  getStorage() {
    try {
      return JSON.parse(localStorage.getItem(this.storageKey)) || { items: {}, lru: [], timestamp: Date.now() };
    } catch (e) {
      console.error('Cache storage corrupted, resetting...', e);
      this.clear();
      return { items: {}, lru: [], timestamp: Date.now() };
    }
  }

  setStorage(data) {
    try {
      const serialized = JSON.stringify(data);
      if (serialized.length > this.maxStorageSize) {
        this.evictUntilFits(data, serialized.length);
      }
      localStorage.setItem(this.storageKey, serialized);
    } catch (e) {
      console.error('Storage error, clearing cache...', e);
      this.clear();
    }
  }

  evictUntilFits(data, currentSize) {
    while (currentSize > this.maxStorageSize && data.lru.length > 0) {
      const oldestKey = data.lru.shift();
      delete data.items[oldestKey];
      currentSize = JSON.stringify(data).length;
    }
  }

  get(key) {
    const storage = this.getStorage();
    const encodedKey = this.getKey(key);
    const item = storage.items[encodedKey];
    
    if (!item) return null;

    // Check expiration
    if (item.expires && item.expires < Date.now()) {
      this.remove(encodedKey);
      return null;
    }

    // Update LRU
    storage.lru = storage.lru.filter(k => k !== encodedKey);
    storage.lru.push(encodedKey);
    this.setStorage(storage);

    return item.value;
  }

  set(key, value, expirationDays = 1) {
    const storage = this.getStorage();
    const encodedKey = this.getKey(key);

    // Update LRU and check size
    storage.lru = storage.lru.filter(k => k !== encodedKey);
    while (storage.lru.length >= this.maxSize) {
      const oldestKey = storage.lru.shift();
      delete storage.items[oldestKey];
    }
    storage.lru.push(encodedKey);

    // Set new value
    storage.items[encodedKey] = {
      value: value,
      expires: expirationDays ? Date.now() + (expirationDays * 24 * 60 * 60 * 1000) : null
    };

    this.setStorage(storage);
  }

  remove(key) {
    const storage = this.getStorage();
    const encodedKey = this.getKey(key);
    delete storage.items[encodedKey];
    storage.lru = storage.lru.filter(k => k !== encodedKey);
    this.setStorage(storage);
  }

  clear() {
    localStorage.setItem(this.storageKey, JSON.stringify({
      items: {},
      lru: [],
      timestamp: Date.now()
    }));
  }
}

// Cache instances with adjusted sizes
export const itemsCache = new StorageCache('itemsCache_', 1000);
export const categoriesCache = new StorageCache('categoriesCache_', 50);
export const subcategoriesCache = new StorageCache('subcategoriesCache_', 100);
export const allCategoriesCache = new StorageCache('allCategoriesCache_', 50);
export const allSubcategoriesCache = new StorageCache('allSubcategoriesCache_', 100);
export const treasuriesCache = new StorageCache('treasuriesCache_', 50);
export const balancesCache = new StorageCache('balancesCache_', 1000);
export const allTreasuriesCache = new StorageCache('allTreasuriesCache_', 200);