import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import CustomSelectInput from '../components/CustomSelectInput';
import Spinner from '../Modals/Spinner';
import {categoriesCache, subcategoriesCache, allCategoriesCache, allSubcategoriesCache} from '../services/cache';
import '../styles/Category.css';

function Category({ user, showModal }) {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const cacheKey = categoriesCache.getKey(user.id);
    const cachedData = categoriesCache.get(cacheKey);
    if (cachedData) {
      setCategories(cachedData.categories);
      return;
    }
    try {
      const response = await makeAuthenticatedRequest(true, 'get', `/categories/user/${user.id}`);
      if (Array.isArray(response.data)) {
        const categories = response.data.sort((a, b) => a.name - b.name);
        categoriesCache.set(cacheKey, { categories });
        setCategories(categories);
      }
    } catch (error) {
      setError("Failed to fetch categories");
    }
  };
  const fetchSubcategories = async (id) => {
    if (id) {
      const cacheKey = subcategoriesCache.getKey(id); // Use category ID as cache key
      const cachedData = subcategoriesCache.get(cacheKey);
      if (cachedData) {
        setSubcategories(cachedData.subcategories);
        return;
      }
      try {
        const response = await makeAuthenticatedRequest(true, 'get', `/subcategories/category/${id}`);
        if (Array.isArray(response.data)) {
          const subcategories = response.data.sort((a, b) => a.name - b.name);
          subcategoriesCache.set(cacheKey, { subcategories });
          setSubcategories(subcategories);
        }
      } catch (error) {
        setError("Failed to fetch subcategories");
      }
    } else {
      setSubcategories([]);
      setSelectedSubcategory('');
    }
  };
  const fetchItems = async (category, subcategory) => {
    try {
      const queryParams = {
        categoryId: category,
        subcategoryId: subcategory,
      };
      const cleanedQueryParams = Object.fromEntries(
          Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== '')
      );
      const query = new URLSearchParams(cleanedQueryParams);
      const response = await makeAuthenticatedRequest(true, 'get', `/items/category/${user.id}?${query.toString()}`);
      const items = response.data;
      if (Array.isArray(items)) {
        setItems(items);
      }
    } catch (error) {
      showModal("Couldn't get Items");
    } finally {
      setLoading(false);
    }
  };

  const handleAddCategory = async (name) => {
    const exists = categories.some(c => c.name.toLowerCase() === name.toLowerCase().trim());
    if (!exists) {
      try {
        const response = await makeAuthenticatedRequest(true, 'post', '/categories', { name, userId: user.id });
        allCategoriesCache.clear();
        categoriesCache.clear();
        await fetchCategories();
        setSelectedCategory(response.data.id);
        setSelectedSubcategory('');
        setSubcategories([]);
        setError('');
      } catch (error) {
        setError("Failed to add category");
      }
    } else{
      setError("Category with same name exists");
    }
  };
  const handleEditCategory = async (newName) => {
    if (newName && newName.trim() !== '') {
      const exists = categories.some(c => c.name.toLowerCase() === newName.toLowerCase().trim());
      if (!exists) {
        try {
          await makeAuthenticatedRequest(true, 'put', `/categories/${selectedCategory}`, { name: newName });
          allCategoriesCache.clear();
          categoriesCache.clear();
          await fetchCategories();
          await fetchItems(selectedCategory, selectedSubcategory);
          setError('');
        } catch (error) {
          setError("Failed to update category");
        }
      } else{
        setError("Category with same name exists");
      }
    }
  };
  const handleMergeCategories = async (id) => {
    try {
      await makeAuthenticatedRequest(true, 'put', `/categories/merge/${selectedCategory}/${id}`);
      allCategoriesCache.clear();
      categoriesCache.clear();
      allSubcategoriesCache.clear();
      subcategoriesCache.clear();
      await fetchCategories();
      await fetchItems(selectedCategory, selectedSubcategory);
      setError('');
    } catch (error) {
      setError("Failed to merge category");
    }
  };
  const handleDeleteCategory = async () => {
    try {
      await makeAuthenticatedRequest(true, 'delete', `/categories/${selectedCategory}`);
      allCategoriesCache.clear();
      categoriesCache.clear();
      await fetchCategories();
      setSelectedCategory('');
      await fetchItems();
      setSubcategories([]);
      setError('');
    } catch (error) {
      setError("Failed to delete category");
    } 
  };
  const handleAddSubcategory = async (name) => {
    const exists = subcategories.some(sc => sc.name.toLowerCase() === name.toLowerCase().trim());
    if (!exists) {
      try {
        const response = await makeAuthenticatedRequest(true, 'post', '/subcategories', { name, categoryId: selectedCategory });
        allSubcategoriesCache.clear();
        subcategoriesCache.clear();
        await fetchSubcategories(selectedCategory);
        setSelectedSubcategory(response.data.id);
        setError('');
      } catch (error) {
        setError("Failed to add subcategory");
      }
    } else{
      setError("Subcategory with same name exists");
    }
  };
  const handleEditSubcategory = async (newName) => {
    if (newName && newName.trim() !== '') {
      const exists = subcategories.some(sc => sc.name.toLowerCase() === newName.toLowerCase().trim());
      if (!exists) {
        try {
          await makeAuthenticatedRequest(true, 'put', `/subcategories/${selectedSubcategory}`, { name: newName });
          allSubcategoriesCache.clear();
          subcategoriesCache.clear();
          await fetchSubcategories(selectedCategory);
          await fetchItems(selectedCategory, selectedSubcategory);
          setError('');
        } catch (error) {
          setError("Failed to update subcategory");
        }
      } else{
        setError("Subcategory with same name exists");
      }
    }
  };
  const handleMergeSubcategories = async (id) => {
    try {
      await makeAuthenticatedRequest(true, 'put', `/subcategories/merge/${selectedSubcategory}/${id}`);
      allSubcategoriesCache.clear();
      subcategoriesCache.clear();
      await fetchSubcategories(selectedCategory);
      await fetchItems(selectedCategory, selectedSubcategory);
      setError('');
    } catch (error) {
      setError("Failed to merge subcategory");
    }
  };
  const handleDeleteSubcategory = async () => {
    try {
      await makeAuthenticatedRequest(true, 'delete', `/subcategories/${selectedSubcategory}`);
      allSubcategoriesCache.clear();
      subcategoriesCache.clear();
      await fetchSubcategories(selectedCategory);
      await fetchItems(selectedCategory, '');
      setSelectedSubcategory('');
      setError('');
    } catch (error) {
      setError("Failed to delete subcategory");
    }
  };
  const handleSelectedCategory = async(id) => {
    setSelectedCategory(id);
    await fetchSubcategories(id);
    await fetchItems(id, '');
    setSelectedSubcategory('');
  }
  const handleSelectedSubcategory = async(id) => {
    setSelectedSubcategory(id);
    await fetchItems(selectedCategory, id);
  }

  return (
    <div className="category-container">
      <h2>Categorize</h2>
      {error ? 
        <p style={{color: 'red'}}>{error}</p> : 
        selectedCategory && <p>Related Items: {items.length}</p>}
      <div className="category-grid">
        <CustomSelectInput
          label="Category"
          value={selectedCategory}
          options={categories}
          handleChange={handleSelectedCategory}
          handleAdd={handleAddCategory}
          handleDelete={handleDeleteCategory}
          handleEdit={handleEditCategory}
          handleMerge={handleMergeCategories}
        />
        <CustomSelectInput
          label="Subcategory"
          value={selectedSubcategory}
          options={subcategories}
          handleChange={handleSelectedSubcategory}
          handleAdd={handleAddSubcategory}
          handleDelete={handleDeleteSubcategory}
          handleEdit={handleEditSubcategory}
          handleMerge={handleMergeSubcategories}
        />
      </div>
      {loading && <Spinner message="Loading Categories ..." />}
    </div>
  );
}

export default Category;
