// Header.js
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { MdMenu, MdClose } from "react-icons/md";
import { makeAuthenticatedRequest } from '../config/api';
import profilePicture from '../images/default-profile.png';
import logo from '../images/logo.png';
import NotificationBell from './NotificationBell';
import '../styles/Header.css';

function Header({ user, setUser }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (user) {
      fetchSignedUrl();
    }
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [user]);

  const fetchSignedUrl = async () => {
    const getFileNameFromUrl = (url) => {
      const urlObj = new URL(url);
      const fileName = urlObj?.pathname.split('/').pop();
      return fileName;
    };
    
    const isValidImageFile = (fileName) => {
      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
      const fileExtension = fileName ? fileName.slice(fileName.lastIndexOf('.')).toLowerCase() : '';
      return allowedExtensions.includes(fileExtension);
    };

    if (user.profilePictureUrl) {
      const fileName = getFileNameFromUrl(user.profilePictureUrl);
      if (fileName && isValidImageFile(fileName)) {
        const response = await makeAuthenticatedRequest(
          true,
          'get',
          `/users/${user.id}/profile-picture-url?fileName=${fileName}`
        );
        setProfilePictureUrl(response?.data?.signedUrl);
      } else {
        setProfilePictureUrl(user.profilePictureUrl);
      }
    } else {
      setProfilePictureUrl(profilePicture);
    }
  };

  const handleLogout = () => {
    setDropdownOpen(false);
    setMenuOpen(false);
    setUser(null);
    localStorage.clear();
    navigate('/');
    handleLogout2();
  };
  const handleLogout2 = () => {
    setUser(null);
    localStorage.clear();
    navigate('/');
  };
  const menuItems = [
    { path: '/dashboard', label: 'Dashboard' },
    { path: '/categorize', label: 'Categorize' },   
    { path: '/data', label: 'Data' },
    { path: '/balance', label: 'Balance' },
    { path: '/exchange-rates', label: 'Exchange' },   
    { path: '/ai', label: 'Ask AI' },     
  ];

  return (
    <header className="header">
      <div className="header-content">
        <div className="header-left">
          <img
            src={logo}
            alt="My Finance App"
            className="logo"
            onClick={() => navigate('/')}
          />
          
          {user && (
            <nav className="desktop-nav">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={location.pathname === item.path ? 'active' : ''}
                >
                  {item.label}
                </Link>
              ))}
            </nav>
          )}
        </div>

        {user && (
          <div className="header-right">
            <NotificationBell user={user} />
            
            <div className="profile-menu" ref={dropdownRef}>
              <div 
                className="profile-trigger"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <img
                  src={profilePictureUrl || profilePicture}
                  alt="Profile"
                  className="profile-picture-2"
                />
              </div>

              {dropdownOpen && (
                <div className="profile-dropdown">
                  <Link 
                    to="/profile" 
                    className="profile-link" 
                    onClick={() => setDropdownOpen(false)}
                  >
                    Profile
                  </Link>
                  <button onClick={handleLogout} className="logout-button">
                    Logout
                  </button>
                </div>
              )}
            </div>

            <button 
              className="mobile-menu-button"
              onClick={() => setMenuOpen(!menuOpen)}
              aria-label="Toggle menu"
            >
              {menuOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
            </button>
          </div>
        )}
      </div>

      {/* Mobile Navigation */}
      {user && menuOpen && (
        <nav className="mobile-nav" ref={menuRef}>
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={location.pathname === item.path ? 'active' : ''}
              onClick={() => setMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
      )}
    </header>
  );
}

export default Header;