import { useEffect, useState } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {itemsCache, allCategoriesCache, allTreasuriesCache } from '../services/cache';
import FilterSidebar from '../components/FilterSidebar';
import Spinner from '../Modals/Spinner';
import ChartNavigator from '../components/ChartNavigator';
import PieChart from '../components/PieChart';
import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';
import PredictionChart from '../components/PredictionChart';
import '../styles/Dashboard.css';

function Dashboard({ user, currencies, showModal }) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubcategories, setAllSubcategories] = useState([]);
  const [allTreasuries, setAllTreasuries] = useState([]);
  const [filters, setFilters] = useState(localStorage.getItem("dash") ? 
  JSON.parse(localStorage.getItem("dash")).filters :
  {
    categories: [],
    subcategories: [],
    dateRange: { start: '', end: '' },
    debitRange: { min: '', max: '' },
    creditRange: { min: '', max: '' }
  });
  const [currency, setCurrency] = useState(localStorage.getItem("dash") ? 
  JSON.parse(localStorage.getItem("dash")).currency : (user.defaultCurrency || ''));
	const [type] = useState('Actual');
  const [currentChart, setCurrentChart] = useState(localStorage.getItem('chart') || 'Spending/Income Analysis');
	const [filterCurrency, setFilterCurrency] = useState(localStorage.getItem("dash") ? 
  JSON.parse(localStorage.getItem("dash")).filterCurrency : true);

  const convertCurrency = (item, field, selectedCurrency) => {
    if (!item || !field || !item[field]) {
      return 0;
    }
    if (!selectedCurrency) {
      return Number(item[field])
    }
    const itemCurrencyRate = currencies.find((c) => c.abbreviation === item.currency).rateToUSD;
    const selectedCurrencyRate = currencies.find((c) => c.abbreviation === selectedCurrency).rateToUSD;
    return ((Number(item[field]) / itemCurrencyRate) * selectedCurrencyRate);
  };

  useEffect(() => {
    fetchItemsAndTreasuries(filters, type, filterCurrency, currency);
    fetchCategoriesAndSubcategories();
  }, []);

  const fetchItemsAndTreasuries = async (filters, type, filterCurrency, currency) => {
    setLoading(true);
    localStorage.setItem("dash", JSON.stringify({filters, filterCurrency, currency}));
    const newItems = await fetchItems(filters, type,  filterCurrency, currency);
    const newTreasuries = await fetchTreasuriesAndBalances(filterCurrency, currency);
    setItems(newItems);
    setAllTreasuries(newTreasuries);
  };

  const fetchItems = async (filter = filters, type, filterCurrency, currency) => {
    const cacheKey = itemsCache.getKey(filter, type, filterCurrency, currency);
    const cachedData = itemsCache.get(cacheKey);
    if (cachedData) {
      setLoading(false);
      return cachedData.items;
    }
    try {
      const queryParams = {
        categories: filter.categories.join(','),
        subcategories: filter.subcategories.join(','),
        dateStart: filter.dateRange.start,
        dateEnd: filter.dateRange.end,
        debitMin: filter.debitRange.min,
        debitMax: filter.debitRange.max,
        creditMin: filter.creditRange.min,
        creditMax: filter.creditRange.max,
        currency: filterCurrency ? currency : ''
      };
      const cleanedQueryParams = Object.fromEntries(
          Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== null && value !== '')
      );
      const query = new URLSearchParams(cleanedQueryParams);
      const response = await makeAuthenticatedRequest(true, 'get', `/items/filter/${user.id}/${type}?${query.toString()}`);
      const {items} = response.data;
      if (Array.isArray(items)) {
        itemsCache.set(cacheKey, { items });
        return items;
      }
    } catch (error) {
      showModal("Couldn't get Items");
      return [];
    } finally {
      setLoading(false);
    }
  };
  const fetchCategoriesAndSubcategories = async () => {
    const cacheKey = allCategoriesCache.getKey(allCategories, allSubcategories);
    const cachedData = allCategoriesCache.get(cacheKey);
    if (cachedData) {
      setAllCategories(cachedData.allCategories);
      setAllSubcategories(cachedData.allSubcategories);
      return;
    }
    try {
        const response = await makeAuthenticatedRequest(true, 'get', `/categories/user/all/${user.id}`);
        const data = response.data;
        const allCategories = data.map(category => category.name);     
        const allSubcategories = data.flatMap(category => {
          const subcategories = Array.isArray(category.subcategories) ? category.subcategories : [];
          return subcategories.map(sub => ({
            name: sub.name,
            category: category.name,
          }));
        });
        allCategoriesCache.set(cacheKey, { allCategories, allSubcategories });
        setAllCategories(allCategories);
        setAllSubcategories(allSubcategories);
    } catch (error) {
    }
  };
  const fetchTreasuriesAndBalances = async (filterCurrency, currency) => {
    const cacheKey = allTreasuriesCache.getKey(allTreasuries, currency);
    const cachedData = allTreasuriesCache.get(cacheKey);
    if (cachedData) {
      return cachedData.allTreasuries;
    }
    try {
        const response = await makeAuthenticatedRequest(true, 'get', `/treasuries/user/all/${user.id}?currency=${filterCurrency ? currency : ''}`);
        const data = response.data;
        allTreasuriesCache.set(cacheKey, { allTreasuries: data });
        return data;
    } catch (error) {
      showModal("Couldn't get Treasuries");
      return [];
    }
  };

  const getChart = (chart) => {
    switch (chart) {
      case 'Future Forecast':
        return (<div className="chart-container">
          <div className="chart-scroll-wrapper">
            <PredictionChart 
              items={items.map(item => ({
                ...item,
                debit: convertCurrency(item, 'debit', currency),
                credit: convertCurrency(item, 'credit', currency),
              }))}
            />
          </div>
        </div>);
      case 'Spending/Income Analysis':
        return (<div className="chart-container">
          <div className="chart-scroll-wrapper">
            <PieChart 
              items={items.map(item => ({
                ...item,
                debit: convertCurrency(item, 'debit', currency),
                credit: convertCurrency(item, 'credit', currency),
              }))}
            />
          </div>
        </div>)
      case 'Balance History':
        return (<div className="chart-container">
          <div className="chart-scroll-wrapper">
            <LineChart 
              items={items.map(item => ({
                ...item,
                debit: convertCurrency(item, 'debit', currency),
                credit: convertCurrency(item, 'credit', currency),
              }))}
              allTreasuries={allTreasuries.map(treasury => ({
                ...treasury,
                limit: convertCurrency(treasury, 'limit', currency),
                Balances: treasury.Balances.map(balance => ({
                  ...balance,
                  balance: convertCurrency(balance, 'balance', currency),
                  limit: convertCurrency(balance, 'limit', currency),
                })),
              }))}
            />
          </div>
        </div>)
      default:
        return null;
    }
  }

  if (!user || !user.id) return null; // Prevent rendering if user or user.id is missing

  return (
    <div className="dashboard-page">
      <FilterSidebar
        onApplyFilters={(filters) => {
          setFilters(filters);
          fetchItemsAndTreasuries(filters, type, filterCurrency, currency);
        }} 
        onApplyCurrency={(currency, filterCurrency) => {
          fetchItemsAndTreasuries(filters, type, filterCurrency, currency);
        }}            
        currencies={currencies}
        currency={currency}
        setCurrency={setCurrency}
        filterOptions={filters}
        filterCurrency={filterCurrency}
        setFilterCurrency={setFilterCurrency}
        allCategories={allCategories}
        allSubcategories={allSubcategories}
        itemType={type}
      />
      <div className="dashboard-main">
        <ChartNavigator 
          currentChart={currentChart} 
          onChangeChart={setCurrentChart} 
        />
        {getChart(currentChart)}
      </div>
      {loading &&
        <Spinner message="Loading Dashboard..." />}
    </div>
  );
}
export default Dashboard; 