import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {itemsCache, categoriesCache, subcategoriesCache, balancesCache, treasuriesCache, allTreasuriesCache
} from '../services/cache';

const AddEditItemModal = ({
  user,
  Incurrency,
  currencies,
  itemType,
  onClose,
  items,
  editingItemId,
  setEditingItemId,
  fetchItems}) => {
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [type, setType] = useState('Debit');
  const [date, setDate] = useState(() => {
    if (itemType == 'Actual') {
      const today = new Date();
      return today.toISOString().split('T')[0];
    } else {
      return getTomorrowDate();
    }
  });  
  const [value, setValue] = useState(0);
  const [notes, setNotes] = useState('');
  const [currency, setCurrency] = useState(Incurrency);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [convertModalOpen, setConverModalOpen] = useState(false);
  const [treasuries, setTreasuries] = useState([]);
  const [treasuryId, setTreasuryId] = useState('');
  useEffect(() => {
    fetchCategories();
    fetchTreasuries(currency);
    if (editingItemId) {
      const item = items.find(i => i.id === editingItemId);
      if (item) {
        setSelectedCategory(item.categoryId);
        fetchSubcategories(item.categoryId);
        setSelectedSubcategory(item.subcategoryId);
        setDate(new Date(item.date).toISOString().split('T')[0]);
        setValue(item.debit < 0 ? -1 * item.debit : item.debit > item.credit ? item.debit : item.credit);
        setNotes(item.notes);
        setType(item.debit < 0 ? 'Reverse' : item.debit > item.credit ? 'Debit' : 'Credit');
        setCurrency(item.currency);
      }
    }
  }, []);
  const fetchTreasuries = async(currency) => {
    const cacheKey = treasuriesCache.getKey(user.id, true, 0, currency);
    const cachedData = treasuriesCache.get(cacheKey);
    if (cachedData) {
      setTreasuries(cachedData.treasuries);
      setLoading(false);
      return;
    }
    try {
      const response = await makeAuthenticatedRequest(true, 'get', `/treasuries/user/${user.id}?currency=${currency}`);
      if (Array.isArray(response.data)) {
        const sortedT = response.data.sort((a, b) => {a.name.localeCompare(b.name || '')});
        treasuriesCache.set(cacheKey, { treasuries: sortedT });
        setTreasuries(sortedT);
      }
    } catch (error) {
      setError("Couldn't get Treasuries");
    } finally {
      setLoading(false);
    }
  };
  const fetchCategories = async () => {
    const cacheKey = categoriesCache.getKey(user.id);
    const cachedData = categoriesCache.get(cacheKey);
    if (cachedData) {
      setCategories(cachedData.categories);
      return;
    }
    try {
      const response = await makeAuthenticatedRequest(true, 'get', `/categories/user/${user.id}`);
      if (Array.isArray(response.data)) {
        const categories = response.data.sort((a, b) => a.name - b.name);
        categoriesCache.set(cacheKey, { categories });
        setCategories(categories);
      }
    } catch (error) {
      setError("Failed to fetch categories");
    }
  };
  const fetchSubcategories = async (id) => {
    if (id) {
      const cacheKey = subcategoriesCache.getKey(id); // Use category ID as cache key
      const cachedData = subcategoriesCache.get(cacheKey);
      if (cachedData) {
        setSubcategories(cachedData.subcategories);
        return;
      }
      try {
        const response = await makeAuthenticatedRequest(true, 'get', `/subcategories/category/${id}`);
        if (Array.isArray(response.data)) {
          const subcategories = response.data.sort((a, b) => a.name - b.name);
          subcategoriesCache.set(cacheKey, { subcategories });
          setSubcategories(subcategories);
        }
      } catch (error) {
        setError("Failed to fetch subcategories");
      }
    } else {
      setSubcategories([]);
      setSelectedSubcategory('');
    }
  };
  const handleSelectedCategory = async(id) => {
    setSelectedCategory(id);
    await fetchSubcategories(id);
    setSelectedSubcategory('');
  }
  const getNewItem = () => {
    return {
      categoryId: !selectedCategory || selectedCategory === '' ? null : selectedCategory,
      subcategoryId: !selectedSubcategory || selectedSubcategory === '' ? null : selectedSubcategory,
      category: selectedCategory ? categories.find(c => c.id == selectedCategory).name : '',
      subcategory: selectedSubcategory ? subcategories.find(sc => sc.id == selectedSubcategory).name : '',
      debit: type === 'Debit' ? value : type === 'Reverse' ? -1 * value : 0,
      credit: type !== 'Debit' && type !== 'Reverse' ? value : 0,
      type: itemType, //change type
      notes,
      currency,
      date,
      creationDate: new Date(),
      userId: user.id,
    };
  };
  const handleAddItem = async () => {
    try {
      setLoading(true);
      const newItem = getNewItem();
      await makeAuthenticatedRequest(true, 'post', '/items', newItem);
      if (treasuryId) {
        handleUpdateBalance();
      }
      itemsCache.clear();
      await fetchItems();
      onClose();
    } catch (error) {
      console.error(error)
      setError("Failed to add item");
    } finally {
      setLoading(false);
    }
  };
  const handleEditItem = async () => {
    try {
      setLoading(true);
      const newItem = getNewItem();
      await makeAuthenticatedRequest(true, 'put', `/items/${editingItemId}`, newItem);
      setEditingItemId('');
      itemsCache.clear();
      await fetchItems();
      onClose();
    } catch (error) {
      setError(`Failed to ${editingItemId ? 'edit' : 'add'} item`);
    } finally {
      setLoading(false);
    }
  };
  const handleConvertItem = async (copy) => {
    try {
      setLoading(true);
      if (copy) {
        const item = items.find(i => i.id === editingItemId);
        const clone = (({ id, ...o }) => o)(item)
        const itemDate = new Date(item.date);
        const newItemDate = itemDate.setMonth(itemDate.getMonth() + 1);
        const newItem = {...clone, date: new Date (newItemDate)};
        makeAuthenticatedRequest(true, 'post', `/items`, newItem).then(async() => {
          await makeAuthenticatedRequest(true, 'put', `/items/${editingItemId}`, {type: 'Actual'});
        });
      } else {
        await makeAuthenticatedRequest(true, 'put', `/items/${editingItemId}`, {type: 'Actual'});
      }
      setEditingItemId('');
      itemsCache.clear();
      await fetchItems();
      onClose();
    } catch (error) {
      setError(`Failed to ${editingItemId ? 'edit' : 'add'} item`);
    } finally {
      setLoading(false);
    }
  };
  const handleNegativeChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0 || newValue === '') {
      setValue(newValue);
    }
  };
  const handleUpdateBalance = async () => {
    try {
      const treasury = treasuries.find(t => t.id == treasuryId);
      const correctedDate = treasury.lastDate && new Date(treasury.lastDate) > new Date(date) ? treasury.lastDate : date;
      const correctedValue = type == 'Debit' ? value * -1 : value;
      const currentBalance = (Number(treasury.lastBalance) || 0) + Number(correctedValue);
      await makeAuthenticatedRequest(true, 'put', `/treasuries/balance/${treasury.id}`, { lastBalance: currentBalance, date: correctedDate });
      balancesCache.clear();
      treasuriesCache.clear();
      allTreasuriesCache.clear();
      await fetchTreasuries(currency);
    } catch (error) {
      setError("Failed to update balance");
    }
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>{editingItemId ? "Edit Item" : "Add Item"}</h3>
        <p style={{color: 'red'}}>{error}</p>
        <div className="sidebar">
          <div className="stacked-inputs">
            <div>
              <label htmlFor="category">Category</label>
              <select
                id='category'
                value={selectedCategory}
                onChange={(e) => handleSelectedCategory(e.target.value)}
              >
                <option value="">Select a Category</option>
                {categories.map(c => 
                  <option key={c.id} value={c.id}>{c.name}</option>
                )}
              </select>
            </div>
            <div>
              <label htmlFor="subcategory">Subcategory</label>
              <select
                id='subcategory'
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
              >
                <option value="">Select a Subcategory</option>
                {subcategories.map(sc => 
                  <option key={sc.id} value={sc.id}>{sc.name}</option>
                )}
              </select>
            </div>
            <div>
              <label htmlFor="value">Amount</label>
              <input
                id="value"
                type="number"
                min={0} step={0.01}
                value={value}
                placeholder="Enter Amount"
                onChange={(e) => handleNegativeChange(e)}
              />
            </div>
            <div>
              <label htmlFor="currency">Currency</label>
              <select
                id="currency"
                value={currency}
                disabled={!editingItemId}
                onChange={(e) => setCurrency(e.target.value)}
              >
                {currencies.map((currency) => (
                  <option key={currency.abbreviation} value={currency.abbreviation}>
                    {currency.name} ({currency.abbreviation})
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="type">Transaction Type</label>
              <select
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="Debit">Debit</option>
                <option value="Reverse">Reverse</option>
                <option value="Credit">Credit</option>
              </select>
            </div>
            <div>
              <label htmlFor="date">Date</label>
              <input
                id="date"
                type="date"
                min={itemType === 'Actual' ? '' : getTomorrowDate()}
                max={itemType === 'Actual' ? new Date().toISOString().split("T")[0] : ''}
                value={date}
                onChange={(e) =>
                  setDate(new Date(e.target.value).toISOString().split("T")[0])
                }
              />
            </div>
            {!editingItemId && <div>
              <label htmlFor="treasury">Treasury</label>
              <select
                id="treasury"
                value={treasuryId}
                onChange={(e) => setTreasuryId(e.target.value)}
              >
                <option value={''}>Please Select Treasury</option>
                {treasuries.map((treasury) => (
                  <option key={treasury.id} value={treasury.id}>{treasury.name}</option>
                ))}
              </select>
            </div>}
            <div>
              <label htmlFor="notes">Notes</label>
              <input
                id="notes"
                type="text"
                value={notes}
                placeholder="Notes...."
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-buttons">
            {(!editingItemId) && <button disabled={loading} className="confirm-btn" onClick={handleAddItem}>Add Item</button>}
            {(itemType == 'Planned' && editingItemId) && Date.now() >= new Date(date).getTime() && <button disabled={loading} className="confirm-btn" onClick={() => setConverModalOpen(true)}>Make Actual</button>}
            {editingItemId && <button disabled={loading} className="confirm-btn" onClick={handleEditItem}>Update Item</button>}
            <button className="cancel-btn" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
      {convertModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <p>Keep Item for next month or move it completely?</p>
            <div className="modal-buttons">
              <button className="confirm-btn" onClick={() => {setConverModalOpen(false); handleConvertItem(true)}}>Copy & Update</button>
              <button className="confirm-btn" onClick={() => {setConverModalOpen(false); handleConvertItem(false)}}>Move</button>
              <button className="cancel-btn" onClick={() => setConverModalOpen(false)}>Close</button>
            </div>
          </div>
        </div>)}
    </div>
  );
};

export default AddEditItemModal;