import React, { useState, useEffect } from 'react';
import '../styles/AI.css';
import { makeAuthenticatedRequest } from '../config/api';

function AI({ user, showModal }) {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [primed, setPrimed] = useState(false);
  const [showCustomInput, setShowCustomInput] = useState(false);
  useEffect(() => {
    if (!primed) {
      handleTrainAI();
    }
  }, [primed]);

  const handleTrainAI = async () => {
    setLoading(true);
    try {
      const trainResponse = await makeAuthenticatedRequest(true, 'post', `/ai/train/${user.id}`);
      const jobId = trainResponse.jobId;
      const checkStatus = async () => {
        const status = await makeAuthenticatedRequest(true, 'get', `/ai/training-status/${jobId}`);
        if (status.status === 'succeeded') {
          setPrimed(true);
          setLoading(false);
        } else if (status.status === 'failed') {
          showModal('Training failed');
          setLoading(false);
        } else {
          setTimeout(checkStatus, 30000);
        }
      };
      checkStatus();
    } catch (error) {
      showModal('Error training AI');
      setLoading(false);
    }
   };

  const handleAskAI = async () => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedRequest(true, 'post', `/ai/ask/${user.id}`, { question });
      setAnswer(response.data.answer);
    } catch (error) {
      showModal('Error asking AI');
      setAnswer('Failed to get an answer from AI');
    }
    setLoading(false);
  };

  const questions = [
    "What's my total balance?",
    "How much did I spend this month?",
    "What are my main spending categories?",
    "Custom question"
  ];
  
  if (!user || !user.id) return null; // Prevent rendering if user or user.id is missing

return (
  <div className="ai-container">
    <h2>Ask AI</h2>
    <select 
      value={question} 
      onChange={(e) => {
        if (e.target.value === "Custom question") {
          setShowCustomInput(true);
        } else {
          setQuestion(e.target.value);
          setShowCustomInput(false);
        }
      }}
    >
      {questions.map(q => <option key={q} value={q}>{q}</option>)}
    </select>
    
    {showCustomInput && (
      <input
        type="text"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Enter your question..."
      />
    )}

    <button onClick={handleAskAI} disabled={loading || !primed}>
      {loading ? 'Processing...' : 'Ask'}
    </button>

    {answer && (
      <div>
        <h4>Answer:</h4>
        <p>{loading ? 'Thinking...' : answer}</p>
      </div>
    )}
  </div>
);
}

export default AI;