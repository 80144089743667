import React, { useState } from 'react';

function MergeModal({onClose, modalTitle, onConfirm, options}) {
  const [newOption, setNewOption] = useState('');

  return (
    <div className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Merge {modalTitle}</h3>
        <select value={newOption} onChange={(e) => setNewOption(e.target.value)}>
          <option value="">Select {modalTitle}</option>
          {options.map(o => 
            <option key={o.id} value={o.id}>{o.name}</option>
          )}
        </select>
        <div className="modal-buttons">
          <button 
            className="confirm-delete-btn" 
            onClick={() => {
              if(newOption) onConfirm(newOption);
              onClose();
            }}>
            Confirm
          </button>
          <button onClick={onClose} className="cancel-btn">Cancel</button>
        </div>
        </div>
    </div>
  );
}

export default MergeModal;