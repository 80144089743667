import React, { useState } from 'react';

function EditModal({onClose, modalTitle, onConfirm, currentName}) {
  const [newName, setNewName] = useState(currentName);

  return (
    <div className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Edit {modalTitle}</h3>
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Enter new name..."
        />
        <div className="modal-buttons">
          <button 
            className="confirm-delete-btn" 
            onClick={() => {
              onConfirm(newName);
              onClose();
            }}>
            Confirm
          </button>
          <button onClick={onClose} className="cancel-btn">Cancel</button>
        </div>
        </div>
    </div>
  );
}

export default EditModal;