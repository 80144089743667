import React, { useState } from 'react';
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { FaCodeMerge } from "react-icons/fa6";
import DeleteModal from '../Modals/DeleteModal';
import EditModal from '../Modals/EditModal';
import MergeModal from '../Modals/MergeModal';
import AddModal from '../Modals/AddModal';
import '../styles/CustomSelectInput.css';

const CustomSelectInput = ({
  label,
  value,
  options,
  handleAdd,
  handleEdit,
  handleDelete,
  handleChange,
  handleMerge
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);

  return (
    <div className="listbox-container">
      <div className="icon-group">
        {value || value !== '' ? (
          <>
            <button onClick={() => setIsEditModalOpen(true)} title="Edit" className="icon-button edit">
              <FaPencilAlt className="icon-select" />
            </button>
            <button onClick={() => setIsDeleteModalOpen(true)} title="Delete" className="icon-button delete">
              <FaTrash className="icon-select" />
            </button>
            <button onClick={() => setIsMergeModalOpen(true)} title="Merge" className="icon-button add">
              <FaCodeMerge className="icon-select" />
            </button>
          </>
        ) : (
          <button onClick={() => setIsAddModalOpen(true)} title="Add New" className="icon-button add">
          <FaPlus className="icon-select" />
        </button>
        )}
      </div>
      <div className="listbox-content">
        {[...options, { id: null, name: 'N/A' }].map((option) => (
          <div
            key={option.id ?? 'default'}
            onClick={() => handleChange(option.id === value ? '' : option.id)}
            className={`listbox-item ${option.id === value ? 'selected' : ''}`}
          >
            <span>{option.name}</span>
          </div>
        ))}
      </div>
      {isAddModalOpen && (
        <AddModal
          modalTitle={label}
          onConfirm={(name) => handleAdd(name)}
          onClose={() => setIsAddModalOpen(false)}
        />
      )}
      {isEditModalOpen && (
        <EditModal
          modalTitle={label}
          currentName={options.find(opt => opt.id === value)?.name || ''}
          onConfirm={(newName) => handleEdit(newName)}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
      {isMergeModalOpen && (
        <MergeModal
          modalTitle={label}
          options={options.filter(o => o.id != value)}
          onConfirm={(id) => handleMerge(id)}
          onClose={() => setIsMergeModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          modalTitle={label}
          deleteAction={() => handleDelete(value)}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
}

export default CustomSelectInput;