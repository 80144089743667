import React, {useState} from 'react';
import { Bar } from 'react-chartjs-2';
import NextMonthModal from '../Modals/NextMonthModal';
import TrendIndicator from './TrendIndicator';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { FaLightbulb } from 'react-icons/fa';
ChartJS.register(annotationPlugin, ArcElement, Tooltip, Legend);

function PredictionChart({ items }) {
  const [nextMonthModalOpen, setNextMonthModalOpen] = useState(false);
  const [spendingGoal, setSpendingGoal] = useState(80);

  if (!items || items.length === 0) {
    return <p>No data to analyze. Adjust your filters and try again.</p>;
  }
  
  const itemsSorted = items.filter(i => i.date && new Date(i.date)).sort((a,b) => new Date(a.date) - new Date(b.date))

  const calculateMonthlyTotals = (items) => {
    const monthlyTotals = {};
    items.forEach(item => {
      const date = new Date(item.date);
      if (isNaN(date.getTime())) return;
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      monthlyTotals[monthKey] = (monthlyTotals[monthKey] || 0) + Number(item.debit || 0);
    });
    return monthlyTotals;
  };

  const calculateMonthlyIncome = (items) => {
    const monthlyIncome = {};
    items.forEach(item => {
      const date = new Date(item.date);
      if (isNaN(date.getTime())) return;
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      monthlyIncome[monthKey] = (monthlyIncome[monthKey] || 0) + Number(item.credit || 0);
    });
    return monthlyIncome;
  };

  const calculateMovingAverage = (values, window = 3) => {
    if (values.length < window) return values;
    const result = [];
    for (let i = 0; i < values.length; i++) {
      const start = Math.max(0, i - window + 1);
      const windowValues = values.slice(start, i + 1);
      const avg = windowValues.reduce((sum, val) => sum + val, 0) / windowValues.length;
      result.push(avg);
    }
    return result;
  };

  const detectOutliers = (values) => {
    const sortedValues = [...values].sort((a, b) => a - b);
    const q1 = sortedValues[Math.floor(values.length * 0.25)];
    const q3 = sortedValues[Math.floor(values.length * 0.75)];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;
    return values.map(value => value < lowerBound || value > upperBound);
  };

  const calculateNextMonthPrediction = (monthlyData) => {
    // Get last 6 months of data
    const recentMonths = Object.entries(monthlyData)
      .sort(([a], [b]) => new Date(b) - new Date(a))
      .slice(0, 6)
      .map(([_, value]) => value);

    if (recentMonths.length === 0) return 0;

    // Remove outliers
    const isOutlier = detectOutliers(recentMonths);
    const cleanValues = recentMonths.filter((_, i) => !isOutlier[i]);
    
    // Calculate trend using moving average
    const movingAvg = calculateMovingAverage(cleanValues);
    
    // Calculate the average change
    let trendChange = 0;
    for (let i = 1; i < movingAvg.length; i++) {
      trendChange += movingAvg[i] - movingAvg[i - 1];
    }
    trendChange = trendChange / (movingAvg.length - 1);

    // Use the most recent non-outlier value as base
    const baseValue = cleanValues[0] || recentMonths[0];
    
    // Calculate prediction with dampened trend
    const trendFactor = 0.5; // Dampen the trend impact
    const prediction = baseValue + (trendChange * trendFactor);

    // Calculate bounds based on recent history
    const maxRecent = Math.max(...recentMonths);
    const minRecent = Math.min(...recentMonths);
    const avgRecent = recentMonths.reduce((sum, val) => sum + val, 0) / recentMonths.length;
    const range = maxRecent - minRecent;

    // Constrain prediction to reasonable bounds
    return Math.max(
      Math.min(
        prediction,
        avgRecent + range * 0.15 // Cap at 15% above average
      ),
      avgRecent - range * 0.15 // Cap at 15% below average
    );
  };

  const calculateSavingsGrowth = (monthlyData, monthlyIncome) => {
    let runningTotal = 0;
    const growthData = {};
    
    Object.keys(monthlyData).forEach(month => {
      const expenses = monthlyData[month] || 0;
      const income = monthlyIncome[month] || 0;
      runningTotal += (income - expenses);
      growthData[month] = runningTotal;
    });
  
    return growthData;
  };

  const monthlyData = calculateMonthlyTotals(itemsSorted);
  const monthlyIncome = calculateMonthlyIncome(itemsSorted);
  const prediction = calculateNextMonthPrediction(monthlyData);
  const incomePrediction = calculateNextMonthPrediction(monthlyIncome);

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const nextMonthLabel = nextMonth.toLocaleString('default', { month: 'short', year: 'numeric' });
  const pastMonthsIncomeAverage = Object.values(monthlyIncome)
    .reduce((sum, value) => sum + value, 0) / Object.values(monthlyIncome).length;
  const savingsGrowth = calculateSavingsGrowth(monthlyData, monthlyIncome);
  
  // Calculate predicted savings based on the trend
  const currentSavings = Object.values(savingsGrowth).slice(-1)[0] || 0;
  const predictedNetSavings = incomePrediction - prediction;
  const predictedSavings = currentSavings + predictedNetSavings;
  
  const data = {
    labels: [...Object.keys(monthlyData), nextMonthLabel],
    datasets: [
      {
        label: 'Monthly Spending',
        data: [...Object.values(monthlyData), prediction],
        backgroundColor: 'rgba(255, 99, 132, 0.6)'
      },
      {
        label: 'Monthly Income',
        data: [...Object.values(monthlyIncome), incomePrediction],
        backgroundColor: 'rgba(75, 192, 192, 0.6)'
      },
      {
        label: 'Net Savings',
        data: [...Object.values(savingsGrowth), predictedSavings],
        type: 'line',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false
      },
      {
        label: 'Savings Goal',
        data: Array(Object.keys(monthlyData).length + 1).fill(pastMonthsIncomeAverage * (spendingGoal/100)),
        type: 'line',
        borderColor: 'rgba(255, 215, 0, 1)',
        borderWidth: 2,
        borderDash: [5, 5],
      }
    ]
  };

  return (
    <div className="chart">
      <h3>Spending Prediction</h3>
      <input 
        type="number" 
        value={spendingGoal} 
        onChange={(e) => setSpendingGoal(e.target.value)}
        min="0" 
        max="100" 
        title='Spending Goal'
        style={{
          position: 'absolute',
          top: '5px',
          right: '0',
          zIndex: 1,
          border: '1px solid #ddd',
          padding: '4px',
          borderRadius: '4px',
          width: '50px',
          marginRight: '125px'
        }}
      />
      <button
        onClick={() => setNextMonthModalOpen(true)}
        title="Next Month Suggestions"
        style={{
          position: 'absolute',
          top: '5px',
          right: '80px',
          height: '25px',
          zIndex: 1,
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          padding: '8px 16px',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        <FaLightbulb />
      </button>
      <TrendIndicator items={items} />
      <Bar 
        data={data} 
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  const value = context.raw.toFixed(2);
                  switch(context.dataset.label) {
                    case 'Monthly Spending':
                      return `Expenses: ${value}`;
                    case 'Monthly Income': 
                      return `Income: ${value}`;
                    case 'Net Savings':
                      return `Savings: ${value}`;
                    case 'Savings Goal':
                      return `Target savings: ${value} (${spendingGoal}% of avg income)`;
                    default:
                      return `${context.dataset.label}: ${value}`;
                  }
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Amount'
              }
            },
            x: {
              title: {
                display: true,
                text: 'Month'
              }
            }
          }
        }} 
      />
      {nextMonthModalOpen && (
        <NextMonthModal 
          onClose={() => setNextMonthModalOpen(false)}
          items={items}
          spendingGoal={spendingGoal}
        />
      )}
    </div>
  );
}

export default PredictionChart;