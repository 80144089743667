import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../config/api';
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from '../Modals/Spinner';
import CurrencySelector from '../components/CurrencySelector';
import '../styles/Register.css';

function Register({ showModal, currencies }) {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [strength, setStrength] = useState(null);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState('');

  const calculatePasswordStrength = (password) => {
    if (!password) return null;
    if (password.length < 6) return {text: "Weak", color: "#Ff0000"};
    if (password.match(/[A-Z]/) && password.match(/[0-9]/) && password.length >= 8) return {text: "Strong", color: "#4CAF50"};
    return {text: "Medium", color: "#FFA500"};
  };

  const handlePassword = (value) => {
    setPassword(value);
    setStrength(calculatePasswordStrength(value));
  }
  const handleRegister = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(''); // Clear previous errors

    if (!name || !email || !password || !rePassword || !birthdate || !gender || !defaultCurrency) {
      setError("Please fill out all fields.");
      setIsLoading(false);
      return;
    }
    if (password !== rePassword) {
      setError("Passwords do not match.");
      setIsLoading(false);
      return;
    }
    if (password.length < 6) {
      setError("Password at least to be 6 charachters.");
      setIsLoading(false);
      return;
    }
    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      setIsLoading(false);
      return;
    }

    makeAuthenticatedRequest(false, 'post', '/users/register', { email, name, birthdate, gender, password, currencies: selectedCurrencies, defaultCurrency, captchaToken })
      .then(response => {
        setTimeout(() => {
          showModal("Registered Successfuly, Check your Email for activation link.")
          navigate('/');
        }, 2000);
      })
      .catch(error => {
        setError(`Registration failed: ${error.response.data.error}`);
        setIsLoading(false);
      });
  };

  const handleCaptcha = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div className="register-container">
      <h2>Register</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleRegister}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
          className={error && !name ? 'input-error' : ''}
        />
        <label htmlFor="birthdate">Birth Date</label>
        <input
          type="date"
          placeholder="Birth Date"
          value={birthdate}
          onChange={e => setBirthdate(e.target.value)}
          required
          className={error && !birthdate ? 'input-error' : ''}
        />
        <label htmlFor="gender">Gender</label>
        <select
          value={gender}
          onChange={e => setGender(e.target.value)}
          required
          className={error && !gender ? 'input-error' : ''}
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          className={error && !email ? 'input-error' : ''}
        />
        <label htmlFor="password">Password{<strong style={{color: strength?.color}}>{strength?.text}</strong>}</label>
        <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => handlePassword(e.target.value)}
            required
            className={error && !password && (rePassword && password !== rePassword) ? 'input-error' : ''}
          />
        <label htmlFor="repassword">Re-Enter Password</label>
        <input
          type="password"
          placeholder="Re-Enter Password"
          value={rePassword}
          onChange={e => {setRePassword(e.target.value); if(e.target.value !== password) {setError("Password not matching")} else {setError('')}}}
          required
          className={error && password !== rePassword ? 'input-error' : ''}
        />
        <label>Select Currencies</label>
        <CurrencySelector
          allCurrencies={currencies}
          selectedCurrencies={selectedCurrencies}
          setSelectedCurrencies={setSelectedCurrencies}
          defaultCurrency={defaultCurrency}
          setDefaultCurrency={setDefaultCurrency}
        />
        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey="6LfM3HkqAAAAAOsdhbhdBekZpa6AiXO8IjFLjlUp"
            onChange={handleCaptcha}
          />
        </div>
        <button type="submit" disabled={isLoading}>
          Register
        </button>
        {isLoading && <Spinner message="Registering"/>}
      </form>
    </div>
  );
}

export default Register;