import React, { useState, useEffect, useRef } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import { FaBell } from 'react-icons/fa';
import '../styles/NotificationBell.css';

const NotificationBell = ({ user }) => {
    const [notifications, setNotifications] = useState(new Map());
    const [unreadCount, setUnreadCount] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const dropdownRef = useRef(null);
    const iconRef = useRef(null);
    const pollIntervalRef = useRef(null);

    // Fetch notifications from the server
    const fetchNotifications = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await makeAuthenticatedRequest(true, 'get', `/notifications/${user.id}`);
            const newNotifications = response.data;

            setNotifications(new Map(newNotifications.map(n => [n.id, n])));
            setUnreadCount(newNotifications.filter((n) => !n.isRead).length);
        } catch (error) {
            console.error('Failed to fetch notifications', error);
            setError('Failed to load notifications');
        } finally {
            setLoading(false);
        }
    };

    // Mark a notification as read
    const markAsRead = async (notificationId) => {
        try {
            await makeAuthenticatedRequest(true, 'put', `/notifications/${notificationId}/read`);
            setNotifications((prev) => {
                const updated = new Map(prev);
                if (updated.has(notificationId)) {
                    updated.set(notificationId, {
                        ...updated.get(notificationId),
                        isRead: true,
                    });
                }
                return updated;
            });
            setUnreadCount((prev) => Math.max(prev - 1, 0));
        } catch (error) {
            console.error('Failed to mark notification as read', error);
        }
    };

    // Remove a notification
    const remove = async (notificationId) => {
        try {
            await makeAuthenticatedRequest(true, 'delete', `/notifications/${notificationId}`);
            setNotifications((prev) => {
                const updated = new Map(prev);
                updated.delete(notificationId);
                return updated;
            });
            setUnreadCount((prev) => Math.max(prev - 1, 0));
        } catch (error) {
            console.error('Failed to delete notification', error);
        }
    };

    // Request notification permission on mount
    useEffect(() => {
        if ('Notification' in window && Notification.permission === 'default') {
            Notification.requestPermission();
        }
    }, []);

    // Polling for new notifications every 30 seconds
    useEffect(() => {
        fetchNotifications();
        pollIntervalRef.current = setInterval(fetchNotifications, 30000);
        return () => clearInterval(pollIntervalRef.current);
    }, [user.id]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                iconRef.current &&
                !iconRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="notification-bell-container">
            <div
                ref={iconRef}
                className="notification-icon"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowDropdown((prev) => !prev);
                }}
            >
                <FaBell size={20} />
                {unreadCount > 0 && <span className="notification-count">{unreadCount}</span>}
            </div>

            {showDropdown && (
                <div ref={dropdownRef} className="notification-dropdown" onClick={(e) => e.stopPropagation()}>
                    {loading ? (
                        <div className="loading-spinner">Loading...</div>
                    ) : error ? (
                        <div className="error-message">{error}</div>
                    ) : notifications.size === 0 ? (
                        <div className="no-notifications">No notifications</div>
                    ) : (
                        Array.from(notifications.values()).map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                            >
                                <div className="notification-title">{notification.title}</div>
                                <div className="notification-message">{notification.message}</div>
                                <div className="notification-time">
                                    <a
                                        onClick={() => remove(notification.id)}
                                        style={{ color: 'blue', marginRight: 10, cursor: 'pointer' }}
                                    >
                                        Delete
                                    </a>
                                    <a
                                        onClick={() => markAsRead(notification.id)}
                                        style={{ color: 'blue', marginRight: 10, cursor: 'pointer' }}
                                    >
                                        Mark Read
                                    </a>
                                    {new Date(notification.createdAt).toLocaleString()}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationBell;
