import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../config/api';
import ReCAPTCHA from 'react-google-recaptcha';
import '../styles/Register.css';
import Spinner from '../Modals/Spinner';

function ChangePassword({ user, showModal }) {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [strength, setStrength] = useState(null);

  const calculatePasswordStrength = (password) => {
    if (!password) return null;
    if (password.length < 6) return {text: "Weak", color: "#Ff0000"};
    if (password.match(/[A-Z]/) && password.match(/[0-9]/) && password.length >= 8) return {text: "Strong", color: "#4CAF50"};
    return {text: "Medium", color: "#FFA500"};
  };
  const handlePassword = (value) => {
    setPassword(value);
    setStrength(calculatePasswordStrength(value));
  }

  const handleRegister = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(''); // Clear previous errors

    if (!password || !rePassword) {
      setError("Please fill out all fields.");
      setIsLoading(false);
      return;
    }
    if (password !== rePassword) {
      setError("Passwords do not match.");
      setIsLoading(false);
      return;
    }
    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      setIsLoading(false);
      return;
    }

    makeAuthenticatedRequest(true, 'put', `/users/password/${user.id}`, { password, captchaToken })
      .then(response => {
        setTimeout(() => {
          showModal("Password changed successfully.")
          navigate('/profile');
        }, 2000);
      })
      .catch(error => {
        setError(`Changing password failed`);
        setIsLoading(false);
      });
  };

  const handleCaptcha = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div className="register-container">
      <h2>Change Password</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleRegister}>
        <label htmlFor="password">Password {<strong style={{color: strength?.color}}>{strength?.text}</strong>}</label>
        <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => {handlePassword(e.target.value); if(e.target.value !== rePassword && rePassword != '') {setError("Password not matching")} else {setError('')}}}
            required
            className={error && !password && (rePassword && password !== rePassword) ? 'input-error' : ''}
          />
        <label htmlFor="repassword">Re-Enter Password</label>
        <input
          type="password"
          placeholder="Re-Enter Password"
          value={rePassword}
          onChange={e => {setRePassword(e.target.value); if(e.target.value !== password) {setError("Password not matching")} else {setError('')}}}
          required
          className={error && password !== rePassword ? 'input-error' : ''}
        />
        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey="6LfM3HkqAAAAAOsdhbhdBekZpa6AiXO8IjFLjlUp"
            onChange={handleCaptcha}
          />
        </div>
        <button type="submit">Change Password</button>
        {isLoading && <Spinner message="Changing password"/>}
      </form>
    </div>
  );
}

export default ChangePassword;