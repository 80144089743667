import React, {useState} from 'react';
import { FaSortAmountDown, FaSortAmountDownAlt  } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight, MdFirstPage, MdLastPage } from 'react-icons/md';

function ItemDataTable({ 
	currencies,
	currency,
	items,
	editingItemId,
	setEditingItemId,
	fetchItems,
	direction,
	setDirection,
	filters,
	currentSortIndex,
	setCurrentSortIndex,
	currentPage,
	setCurrentPage,
	itemsPerPage,
	setItemsPerPage,
	totalPages,
	totalItems,
	searchTerm,
	filterCurrency, 
	type
 }) { 
	const convertCurrency = (item, field, selectedCurrency) => {
		if (!item || !field || !item[field]) {
		  return 0;
		}
		if (!selectedCurrency) {
		  return Number(item[field])
		}
		const itemCurrencyRate = currencies.find((c) => c.abbreviation === item.currency).rateToUSD;
		const selectedCurrencyRate = currencies.find((c) => c.abbreviation === selectedCurrency).rateToUSD;
		return ((Number(item[field]) / itemCurrencyRate) * selectedCurrencyRate).toFixed(2);
	};

	const calculateTotal = (items, field, selectedCurrency) => {
		if (!field) {
			return '-';
		}
		const sum = items.reduce((acc, item) => {
			const value = Number(convertCurrency(item, field, selectedCurrency)); // Convert item's field value to USD
			return acc + value;
		}, 0);
		return sum == 0 ? '-' : sum.toFixed(2);
	};

  const handleSort = async(index) => {
    setCurrentSortIndex(index);
    setDirection(!direction);
    await fetchItems(currentPage, itemsPerPage, filters, !direction, index, searchTerm, type, currency, filterCurrency);
  };
	const headerCell = (title, index) => {
    return <th>
      <label onClick={() => handleSort(index)}>{title}</label>
      {currentSortIndex !== index ? '' : !direction ? 
        <div className="sort-icon" onClick={() => handleSort(index)}>
          <FaSortAmountDown />
        </div> :                   
        <div className="sort-icon" onClick={() => handleSort(index)}>
          <FaSortAmountDownAlt />
        </div>}          
    </th>
  }; 


	return(
		<div>
			<div className="pagination">
				<button
					onClick={() => {
						setCurrentPage(1);
						fetchItems(1, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
					}}
					disabled={currentPage === 1}
				>
						<MdFirstPage />
				</button>
				<button
					onClick={() => {
						const prevPage = Math.max(1, currentPage - 1);
						setCurrentPage(prevPage);
						fetchItems(prevPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
					}}
					disabled={currentPage === 1}
				>
						<MdChevronLeft />
				</button>
				<span>
					{currentPage}/{totalPages}
				</span>
				<button
					onClick={() => {
						const nextPage = Math.min(currentPage + 1, totalPages);
						setCurrentPage(nextPage);
						fetchItems(nextPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
					}}
					disabled={currentPage >= totalPages}
				>
					<MdChevronRight />
				</button>
				<button
					onClick={() => {
						setCurrentPage(totalPages);
						fetchItems(totalPages, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
					}}
					disabled={currentPage >= totalPages}
				>
					<MdLastPage />
				</button>
				<div className="items-per-page">
					<label htmlFor="items-per-page-input">Amount</label>
					<input
						id="items-per-page-input"
						type="number"
						value={totalItems < itemsPerPage ? totalItems : itemsPerPage}
						min="1"
						max={totalItems}
						onChange={(e) => {
							const value = Math.max(
								1,
								Math.min(Number(e.target.value), totalItems)
							);
							setItemsPerPage(value);
							setCurrentPage(1); // Reset to page 1
							fetchItems(1, value, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
						}}
					/>
				</div>
			</div>
			<div className="data-table-scroll">
				<table>
					<thead>
						<tr>
							{headerCell("Category", 0)}
							{headerCell("Subcategory", 1)}
							{headerCell("Date", 2)}
							{headerCell("Debit", 3)}
							{headerCell("Credit", 4)}
							{headerCell("Currency", 5)}
							{headerCell("Notes", 6)}
						</tr>
						<tr style={{color:'red', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
							<th colSpan={3}>
							</th>
							<th>
								{calculateTotal(items, 'debit', currency)}
							</th>
							<th>
								{calculateTotal(items, 'credit', currency)}
							</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{items.map((item) => (
							<tr
								key={item.id}
								onClick={() => editingItemId === item.id ? setEditingItemId('') :  setEditingItemId(item.id)}
								className={
									editingItemId && item.id === editingItemId
										? "editable-row"
										: "regular-row"
								}
							>
								<td>{item.category || "N/A"}</td>
								<td>{item.subcategory || "N/A"}</td>
								<td>{new Date(item.date).toLocaleDateString()}</td>
								<td>{convertCurrency(item, 'debit', currency)}</td>
								<td>{convertCurrency(item, 'credit', currency)}</td>
								<td>{item.currency}</td>
								<td>{item.notes}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
export default ItemDataTable;