import React, { useState, useEffect} from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {itemsCache, allCategoriesCache} from '../services/cache';
import FilterSidebar from '../components/FilterSidebar';
import DeleteModal from '../Modals/DeleteModal';
import Spinner from '../Modals/Spinner';
import AddEditItemModal from '../Modals/AddEditItemModal';
import ItemsBulkUploadModal from '../Modals/ItemsBulkUploadModal';
import ItemDataTable from '../components/ItemDataTable';
import ItemDataTableActions from '../components/ItemDataTableActions';
import TypeToggle from '../components/TypeToggle';
import SearchBar from '../components/SearchBar';
import '../styles/Data.css';

function Data({ user, currencies, showModal }) {
  const [loading, setLoading] = useState(true); // New loading state
  const [items, setItems] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null); // Track the item being edited

  const [isAddEditItemModalOpen, setIsAddEditItemModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [deleteAction, setDeleteAction] = useState(null);
  const [direction, setDirection] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).dir :false);
  const [currentSortIndex, setCurrentSortIndex] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).sortIndex : 2);
  const [currentPage, setCurrentPage] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).page :1);
  const [itemsPerPage, setItemsPerPage] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).itemsPerPage : Math.floor((window.innerHeight - 400)/45));
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubcategories, setAllSubcategories] = useState([]);
  const [filters, setFilters] = useState(localStorage.getItem("data") ? 
    JSON.parse(localStorage.getItem("data")).filter :
    {
      categories: [],
      subcategories: [],
      dateRange: { start: '', end: '' },
      debitRange: { min: '', max: '' },
      creditRange: { min: '', max: '' },
      peopleRange: {max: ''}
    }
  );
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).searchTerm : '');
  const [type, setType] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).type : 'Actual');
	const [currency, setCurrency] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).currency : (user.defaultCurrency || ''));
	const [filterCurrency, setFilterCurrency] = useState(localStorage.getItem("data") ? 
  JSON.parse(localStorage.getItem("data")).filterCurrency : true);

  useEffect(() => {
    fetchItems(currentPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
    fetchCategoriesAndSubcategories();
  }, []);
  
  const fetchItems = async (
    page = 1, 
    itemsPerPage = 5,
    filter = filters,
    dir = direction,
    sortIndex = currentSortIndex,
    searchTerm,
    type,
    currency,
    filterCurrency) => {
    localStorage.setItem("data", JSON.stringify({filter, dir, searchTerm, type, currency, sortIndex, page, itemsPerPage, filterCurrency}));
    const cacheKey = itemsCache.getKey(page, itemsPerPage, filter, dir, sortIndex, searchTerm, type, currency, filterCurrency);
    const cachedData = itemsCache.get(cacheKey);
    if (cachedData) {
      setItems(cachedData.items);
      setTotalPages(cachedData.totalPages);
      setCurrentPage(cachedData.currentPage);
      setTotalItems(cachedData.totalItems);
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const queryParams = {
        page,
        limit: itemsPerPage,
        categories: filter.categories.join(','),
        subcategories: filter.subcategories.join(','),
        dateStart: filter.dateRange.start,
        dateEnd: filter.dateRange.end,
        debitMin: filter.debitRange.min,
        debitMax: filter.debitRange.max,
        creditMin: filter.creditRange.min,
        creditMax: filter.creditRange.max,
        peopleMax: filter.peopleRange.max,
        direction: dir,
        currency: filterCurrency ? currency : '',
        sortIndex,
        searchTerm: searchTerm || ''
      };
      const cleanedQueryParams = Object.fromEntries(
          Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== null && value !== '')
      );
      const query = new URLSearchParams(cleanedQueryParams);
      const response = await makeAuthenticatedRequest(true, 'get', `/items/filter/paging/${user.id}/${type}?${query.toString()}`);
      const { items, totalItems, totalPages, currentPage } = response.data;
      if (Array.isArray(items)) {
        itemsCache.set(cacheKey, { items, totalItems, totalPages, currentPage });
        setItems(items);
        setTotalItems(totalItems);
        setTotalPages(totalPages);
        if (items.length === 0 && currentPage > 1) {
          setCurrentPage(currentPage-1);
          await fetchItems(currentPage-1, itemsPerPage, filter, dir, sortIndex, searchTerm, type, currency, filterCurrency);
        }
        setCurrentPage(currentPage);
      }
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoriesAndSubcategories = async () => {
    const cacheKey = allCategoriesCache.getKey(allCategories, allSubcategories);
    const cachedData = allCategoriesCache.get(cacheKey);
    if (cachedData) {
      setAllCategories(cachedData.allCategories);
      setAllSubcategories(cachedData.allSubcategories);
      return;
    }
    try {
        const response = await makeAuthenticatedRequest(true, 'get', `/categories/user/all/${user.id}`);
        const data = response.data;
        const allCategories = data.map(category => category.name);
        const allSubcategories = data.flatMap(category => {
          const subcategories = Array.isArray(category.subcategories) ? category.subcategories : [];
          return subcategories.map(sub => ({
            name: sub.name,
            category: category.name,
          }));
        });
        allCategoriesCache.set(cacheKey, { allCategories, allSubcategories });
        setAllCategories(allCategories);
        setAllSubcategories(allSubcategories);
    } catch (error) {
    }
  };

  const openAddEditItemModal = () => setIsAddEditItemModalOpen(true);
  const closeAddEditItemModal = () => {
    setIsAddEditItemModalOpen(false);
    fetchCategoriesAndSubcategories(); // Trigger data refresh when the modal closes
  };

  const openUploadModal = () => setIsUploadModalOpen(true);

  const handleType = (newType) => {
    setEditingItemId('');
    setType(newType);
    fetchItems(1, itemsPerPage, filters, direction, currentSortIndex, searchTerm, newType, currency, filterCurrency);
  };

  const handleSearch = (term) => {
    setEditingItemId('');
    setSearchTerm(term);
    setCurrentPage(1);
    fetchItems(1, itemsPerPage, filters, direction, currentSortIndex, term, type, currency, filterCurrency);
  };
  
  const openDeleteModal = (title, action) => {
    setModalTitle(title);
    setDeleteAction(() => action);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteItem = () => {openDeleteModal('Item', async () => await deleteItem());};
  const deleteItem = async () => {
    try {
      await makeAuthenticatedRequest(true, 'delete', `/items/${editingItemId}`);
      itemsCache.clear();
      await fetchItems(currentPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
      setEditingItemId('');
      setIsDeleteModalOpen(false); // Close the modal after successful deletion
    } catch (error) {
      showModal("Failed to delete item");
    }
  };
  const handleDeleteItems = () => {openDeleteModal('Items', () => deleteItems());};
  const deleteItems = async () => {
    try {
      setLoading(true);
      const itemsToDelete = {items: items.map(i => i.id)};
      await makeAuthenticatedRequest(true, 'delete', `/items`, itemsToDelete);
      itemsCache.clear();
      await fetchItems(currentPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
      setEditingItemId('');
      setIsDeleteModalOpen(false); // Close the modal after successful deletion
    } catch (error) {
      showModal("Failed to delete items");
    } finally {
      setLoading(false);
    }
  };
  
  if (!user || !user.id) return null; // Prevent rendering if user or user.id is missing

  return (
    <div className="data-page">
      <FilterSidebar
        onApplyFilters={(filters) => {
          setFilters(filters);
          fetchItems(currentPage,itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
        }} 
        onApplyCurrency={(currency, filterCurrency) => {
          fetchItems(currentPage,itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency);
        }}            
        currencies={currencies}
        currency={currency}
        setCurrency={setCurrency}
        filterOptions={filters}
        filterCurrency={filterCurrency}
        setFilterCurrency={setFilterCurrency}
        allCategories={allCategories}
        allSubcategories={allSubcategories}
        itemType={type}
      />
      <div className="data-main">
      <div className="data-table-container">
        <div className="data-actions-container">
          <SearchBar onSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <TypeToggle type={type} setType={handleType}/>
        </div>
        <ItemDataTableActions
          user={user}
          itemType={type}
          handleDeleteItems={handleDeleteItems}
          handleDeleteItem={handleDeleteItem}
          openAddEditItemModal={openAddEditItemModal}
          openUploadModal={openUploadModal}
          editingItemId={editingItemId}
          setLoading={setLoading}
          showModal={showModal}
          setType={handleType}
          fetchItems={async() => await fetchItems(currentPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency)}
        />
        <ItemDataTable
          items={items} currencies={currencies}
          editingItemId={editingItemId} setEditingItemId={setEditingItemId}
          fetchItems={fetchItems} filters={filters}
          direction={direction} setDirection={setDirection}
          currentSortIndex={currentSortIndex} setCurrentSortIndex={setCurrentSortIndex}
          currentPage={currentPage} setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage}
          totalPages={totalPages} totalItems={totalItems}
          searchTerm={searchTerm} type={type}
          currency={currency} filterCurrency={filterCurrency}
        />
      </div>
      </div>
      {isAddEditItemModalOpen && (
        <AddEditItemModal
          user={user}
          Incurrency={currency}
          currencies={currencies}
          itemType={type}
          onClose={closeAddEditItemModal}
          items={items}
          setEditingItemId={setEditingItemId}
          editingItemId={editingItemId}
          fetchItems={async() => await fetchItems(currentPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency)}
        />
      )}
      {isUploadModalOpen && 
        <ItemsBulkUploadModal 
          user={user}
          onClose={() => setIsUploadModalOpen(false)}
          fetchItems={async() => await fetchItems(currentPage, itemsPerPage, filters, direction, currentSortIndex, searchTerm, type, currency, filterCurrency)}
          fetchCategoriesAndSubcategories={async() => await fetchCategoriesAndSubcategories()}
        />}
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          modalTitle={modalTitle}
          deleteAction={deleteAction}
        />
      )}
      {loading &&
        <Spinner message="Loading Data...."/>}
    </div>
  );
}
export default Data; 