import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../config/api';
import '../styles/CurrencyModal.css';

const CurrencyModal = ({ user, updateCurrencies, allCurrencies, onClose }) => {
  const navigate = useNavigate();
  
  const [selectedCurrencies, setSelectedCurrencies] = useState(user.Currencies?.map(c => c.abbreviation) || []);
  const [defaultCurrency, setDefaultCurrency] = useState(user.defaultCurrency || '');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeCurrencies = async() => {
    setIsLoading(true);
    try {
      const response = await makeAuthenticatedRequest(true, 'put', `/users/currency/${user.id}`, { currencies: selectedCurrencies, defaultCurrency });
      setIsLoading(true);
      setTimeout(() => {
        updateCurrencies(response.data);
        navigate('/profile');
        onClose();
      }, 2000);
    } catch (error) {
      setError(`Updateing currencies failed`);
      setIsLoading(false);
    }
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>User Currencies Update</h3>
        <p style={{color: 'red'}}>{error}</p>
        <div className="currency-selector">
          <div className="checkbox-list">
            {allCurrencies.sort((a, b) => a.name.localeCompare(b.name)).map((currency) => (
              <div key={currency.abbreviation} className="checkbox-item">
                <input
                  type="checkbox"
                  id={`currency-${currency.abbreviation}`}
                  checked={selectedCurrencies.includes(currency.abbreviation)}
                  onChange={() => {
                    const newSelection = selectedCurrencies.includes(currency.abbreviation)
                      ? selectedCurrencies.filter(c => c !== currency.abbreviation)
                      : [...selectedCurrencies, currency.abbreviation];
                    setSelectedCurrencies(newSelection);
                    
                    // If default currency is deselected, update it
                    if (defaultCurrency === currency.abbreviation && !newSelection.includes(currency.abbreviation)) {
                      setDefaultCurrency(newSelection[0] || '');
                    }
                  }}
                />
                <label htmlFor={`currency-${currency.abbreviation}`}>
                  {currency.name} ({currency.abbreviation})
                </label>
              </div>
            ))}
          </div>

          {selectedCurrencies.length > 0 && (
            <div className="default-currency-select">
              <label>Default Currency:</label>
              <select
                value={defaultCurrency}
                onChange={(e) => setDefaultCurrency(e.target.value)}
                className={!defaultCurrency ? 'input-error' : ''}
              >
                <option value="">Select Default Currency</option>
                {selectedCurrencies.map(curr => {
                  const currency = allCurrencies.find(c => c.abbreviation === curr);
                  return (
                    <option key={curr} value={curr}>
                      {currency.name} ({curr})
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        <div className="modal-buttons">
          <button onClick={handleChangeCurrencies} className="confirm-delete-btn" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Confirm'}
          </button>
          <button onClick={onClose} className="cancel-btn">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default CurrencyModal;